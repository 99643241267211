import React, { FC, useEffect, useState } from "react";
import dropdown from "../../../../assets/icons/common/dropdown-azul.svg";
import {
  Header,
  TimeLineContainer,
  Timeline,
  TimelineItem,
  TimelineItemText,
  SmallMenu,
  SmallMenuItem,
  OverlayMenu,
} from "../../../../pages/MyPerformance/styles";
import { formatValue } from "../../../../util/format-value";
import Colors from "../../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../../util/analytics/index";
import Tooltip from 'react-tooltip-lite';
import "./tooltip.css";

interface SummaryProps {
  summary: any;
  opportunities: any[];
  currentOpenMenu: string;
  handleOpenMenu: (value?: string) => void;
  timeline: any;
  dataSetRange: any;
  handleSetMonth: (value: any) => void;
  currentCurrency: any;
  handleSetCurrency: (value: any) => void;
  handleSelectOpportunities: (e: any, v?: string) => void;
  lastSelected?: string;
  // originalOpportunities:any;
}

const Summary: FC<SummaryProps> = ({
  summary,
  opportunities,
  currentOpenMenu,
  handleOpenMenu,
  timeline,
  dataSetRange,
  handleSetMonth,
  currentCurrency,
  handleSetCurrency,
  handleSelectOpportunities,
  lastSelected,
  // originalOpportunities

}) => {
  const cnpj = opportunities[0] ? opportunities[0].cnpj : null;
  const [totalOpportunities, setTotalOpportunities] = useState(0);
  // const [currentOpportunities, setCurrentOpportunities] = useState(0);

  useEffect(() => {
    setTotalOpportunities(opportunities.length);
    // listOpportunities();


  }, [cnpj]);

  // useEffect(() => {
  //   if(lastSelected === undefined){
  //     window?.localStorage?.setItem(
  //       "summary-my-performance",
  //       JSON.stringify({opportunities:"total",color:Colors.gray8} || '')
  //   );
  //   }
  //   // setCurrentOpportunities(opportunities.length);
  // }, [cnpj, opportunities.length]);





  // const listOpportunities = () => {
  //   const listOpportunitiesType: any = {
  //     won: originalOpportunities.filter((item: any) => item.winner),
  //     lost: originalOpportunities.reduce((filtered: any, item: any) => {
  //       if (
  //         item.statusProposal === "Cotação" &&
  //         item.statusQualification === "Classificado" &&
  //         !item.winner
  //       ) {
  //         const nItem = {
  //           highlightColor: Colors.red,
  //           ...item,
  //         };
  //         filtered = [...filtered, nItem];
  //       }
  //       return filtered;
  //     }, []),
  //     disqualified: originalOpportunities.reduce(
  //       (filtered: any, item: any) => {
  //         if (
  //           item.statusProposal === "Cotação" &&
  //           item.statusQualification === "Desclassificado"
  //         ) {
  //           const nItem = {
  //             highlightColor: Colors.orange5,
  //             ...item,
  //           };
  //           filtered = [...filtered, nItem];
  //         }
  //         return filtered;
  //       },
  //       []
  //     ),
  //     declined: originalOpportunities.reduce((filtered: any, item: any) => {
  //       if (item.statusProposal === "Declínio") {
  //         const nItem = {
  //           highlightColor: Colors.purple1,
  //           ...item,
  //         };
  //         filtered = [...filtered, nItem];
  //       }
  //       return filtered;
  //     }, []),
  //   };
  //     window?.localStorage?.setItem(
  //       "opportunities-my-performance",
  //       JSON.stringify(listOpportunitiesType || '')
  //   );
  // };
  const months = [
    { type: "one", label: 1 },
    { type: "third", label: 3 },
    { type: "half", label: 6 },
    { type: "ninety", label: 9 },
    { type: "full", label: 12 },
  ];
  const currency = [
    {
      cipher: "R$",
      name: "Real",
      type: "BRL",
    },
    {
      cipher: "U$",
      name: "Dolar",
      type: "USD",
    },
    {
      cipher: "€",
      name: "Euro",
      type: "EUR",
    },
  ];

  return (
    <>
      <Header>
        <TimeLineContainer>
          <Timeline
            width="10%"
            margin="0 .25rem 0 0"
            menuOpen={currentOpenMenu === "months"}
            mobile="49%"

          >
            <TimelineItem
              width="100%"
              color={Colors.blue6}
              onClick={() => handleOpenMenu("months")}
              cursor={"pointer"}
              isButton={true}
            >
              <div>
                {timeline.type === "last-year"
                  ? dataSetRange?.year
                  : timeline.label}
                <img src={dropdown} alt="dropdown" />
              </div>
              <TimelineItemText color={Colors.gray6}>
                {timeline.type === "last-year"
                  ? "\u00A0"
                  : timeline.type === "one"
                    ? "Mês"
                    : "Meses"}
              </TimelineItemText>
            </TimelineItem>
            {currentOpenMenu === "months" && (
              <>
                <OverlayMenu onClick={() => handleOpenMenu(undefined)} />
                <SmallMenu>
                  {months.map((item, index) => (
                    <SmallMenuItem
                      key={index}
                      onClick={() => handleSetMonth(item)}
                      selected={item.type === timeline.type}
                    >
                      {item.label}
                      {item.label.toString() === "last-year"
                        ? " \u00A0"
                        : item.label === 1
                          ? " mês"
                          : " meses"}
                    </SmallMenuItem>
                  ))}
                </SmallMenu>
              </>
            )}
          </Timeline>
          <Timeline
            width="10%"
            menuOpen={currentOpenMenu === "currency"}
            mobile="49%"
          >
            <TimelineItem
              width="100%"
              color={Colors.blue6}
              onClick={() => handleOpenMenu("currency")}
              cursor={"pointer"}
              isButton={true}
            >
              <div>
                {currentCurrency?.cipher}
                <img src={dropdown} alt="dropdown" />
              </div>
              <TimelineItemText color={Colors.gray6}>Moeda</TimelineItemText>
            </TimelineItem>
            {currentOpenMenu === "currency" && (
              <>
                <OverlayMenu onClick={() => handleOpenMenu(undefined)} />
                <SmallMenu>
                  {currency.map((item, index) => (
                    <SmallMenuItem
                      key={index}
                      onClick={() => handleSetCurrency(item)}
                      selected={item?.cipher === currentCurrency?.cipher}
                    >
                      {item.cipher} {item.name}
                    </SmallMenuItem>
                  ))}
                </SmallMenu>
              </>
            )}
          </Timeline>
          <Timeline width="30%" mobile="100%">
            <TimelineItem width="60%" color={Colors.green5}>
              {formatValue(summary?.totalEarned) || "0"}
              <TimelineItemText>Total de Participação</TimelineItemText>
            </TimelineItem>
            <TimelineItem
              width="40%"
              color={Colors.green5}
              onClick={(ev) => {
                logEvent(
                  eventCategory.linkClick,
                  "click Menor Preço",
                  "Menor Preço"
                );
                handleSelectOpportunities(ev, "won");
              //   window?.localStorage?.setItem(
              //     "summary-my-performance",
              //     JSON.stringify({opportunities:"won",color:Colors.green5} || '')
              // );
              }}
              cursor={"pointer"}
              isButton={true}
              isSelected={lastSelected === "won" ? true : false}
            >
              <Tooltip
                content={`${(summary?.opportunitiesWon * 100 / totalOpportunities).toFixed(2)}% sobre Total de Oportunidades`}
                direction="top"
                tagName="span"

                background="white"
                color="black"
                tipContentClassName="arrow-content-tooltip"
                className="target"
                arrowContent={(
                  <svg style={{ display: 'block' }} viewBox="0 0 21 11" width="20px" height="10px">
                    <path
                      d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
                      style={{ stroke: 'gray', fill: 'white' }}
                    />
                  </svg>
                )}
              >
                {summary?.opportunitiesWon || "0"}
              </Tooltip>
              <TimelineItemText>Menor Preço</TimelineItemText>
            </TimelineItem>
            <TimelineItem width="25%" color={Colors.blue6} renderMobile>
              {summary?.opportunitiesWon +
                summary?.opportunitiesLost +
                summary?.opportunitiesDisqualified +
                summary?.opportunitiesDeclined || "0"}
              <TimelineItemText>Oportunidades</TimelineItemText>
            </TimelineItem>
          </Timeline>
          <Timeline width="50%" margin="0 0 0 .25rem">
            <TimelineItem
              width="25%"
              color={Colors.red}
              onClick={(ev) => {
                logEvent(
                  eventCategory.linkClick,
                  "click Maior Preço",
                  "Maior Preço"
                );
                handleSelectOpportunities(ev, "lost");
              //   window?.localStorage?.setItem(
              //     "summary-my-performance",
              //     JSON.stringify({opportunities:"lost",color:Colors.red} || '')
              // );
              }}
              cursor={"pointer"}
              isButton={true}
              isSelected={lastSelected === "lost" ? true : false}
            >
              <Tooltip
                content={`${(summary?.opportunitiesLost * 100 / totalOpportunities).toFixed(2)}% sobre Total de Oportunidades`}
                direction="top"
                tagName="span"

                background="white"
                color="black"
                tipContentClassName="arrow-content-tooltip"
                className="target"
                arrowContent={(
                  <svg style={{ display: 'block' }} viewBox="0 0 21 11" width="20px" height="10px">
                    <path
                      d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
                      style={{ stroke: 'gray', fill: 'white' }}
                    />
                  </svg>
                )}
              >
                {summary?.opportunitiesLost || "0"}
              </Tooltip>

              <TimelineItemText>Maior Preço</TimelineItemText>
            </TimelineItem>

            <TimelineItem
              width="25%"
              color={Colors.orange1}
              onClick={(ev) => {
                logEvent(
                  eventCategory.linkClick,
                  "click Desqualificada",
                  "Desqualificada"
                );
                handleSelectOpportunities(ev, "disqualified");
              //   window?.localStorage?.setItem(
              //     "summary-my-performance",
              //     JSON.stringify({opportunities:"disqualified",color:Colors.orange1} || '')
              // );
              }}
              cursor={"pointer"}
              isButton={true}
              isSelected={lastSelected === "disqualified" ? true : false}
            >
              <Tooltip
                content={`${(summary?.opportunitiesDisqualified * 100 / totalOpportunities).toFixed(2)}% sobre Total de Oportunidades`}
                direction="top"
                tagName="span"

                background="white"
                color="black"
                tipContentClassName="arrow-content-tooltip"
                className="target"
                arrowContent={(
                  <svg style={{ display: 'block' }} viewBox="0 0 21 11" width="20px" height="10px">
                    <path
                      d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
                      style={{ stroke: 'gray', fill: 'white' }}
                    />
                  </svg>
                )}
              >

                {summary?.opportunitiesDisqualified || "0"}
              </Tooltip>

              <TimelineItemText>Desclassificada</TimelineItemText>
            </TimelineItem>
            <TimelineItem
              width="25%"
              color={Colors.purple}
              onClick={(ev) => {
                logEvent(
                  eventCategory.linkClick,
                  "click Declinadas",
                  "Declinadas"
                );
                handleSelectOpportunities(ev, "declined");
              //   window?.localStorage?.setItem(
              //     "summary-my-performance",
              //     JSON.stringify({opportunities:"declined",color:Colors.purple} || '')
              // );
              }}
              cursor={"pointer"}
              isButton={true}
              isSelected={lastSelected === "declined" ? true : false}
            >
             <Tooltip
                content={`${(summary?.opportunitiesDeclined * 100 / totalOpportunities).toFixed(2)}% sobre Total de Oportunidades`}
                direction="top"
                tagName="span"

                background="white"
                color="black"
                tipContentClassName="arrow-content-tooltip"
                className="target"
                arrowContent={(
                  <svg style={{ display: 'block' }} viewBox="0 0 21 11" width="20px" height="10px">
                    <path
                      d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
                      style={{ stroke: 'gray', fill: 'white' }}
                    />
                  </svg>
                )}
              >

                {summary?.opportunitiesDeclined || "0"}
              </Tooltip>

              <TimelineItemText>Declinadas</TimelineItemText>
            </TimelineItem>
            <TimelineItem
              width="25%"
              color={Colors.gray8}
              cursor={"pointer"}
              isButton={true}
              isSelected={lastSelected === undefined ? true : false}
              onClick={(ev) => {
                logEvent(
                  eventCategory.linkClick,
                  "click Oportunidades",
                  "Oportunidades"
                );
                handleSelectOpportunities(ev, undefined)
              //   window?.localStorage?.setItem(
              //     "summary-my-performance",
              //     JSON.stringify({opportunities:"total",color:Colors.gray8} || '')
              // );
              }}
            >
            <Tooltip
                content={`${totalOpportunities * 100 / totalOpportunities}%`}
                direction="top"
                tagName="span"

                background="white"
                color="black"
                tipContentClassName="arrow-content-tooltip"
                className="target"
                arrowContent={(
                  <svg style={{ display: 'block' }} viewBox="0 0 21 11" width="20px" height="10px">
                    <path
                      d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
                      style={{ stroke: 'gray', fill: 'white' }}
                    />
                  </svg>
                )}
              >

                {totalOpportunities || "0"}
              </Tooltip>

              <TimelineItemText style={{ cursor: "pointer" }}>
                Oportunidades
              </TimelineItemText>

            </TimelineItem>


          </Timeline>
        </TimeLineContainer>
      </Header>

      {/* {modalOpportunities && (
          <InfoModal
          isPercentageInfo
          description={{
            text: `99%`,
          
            // top: 10,
            left: 1070,
          }}
        />
)} */}


    </>
  );
};

export default Summary;
