/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react";
import { Content, Title } from "./styles";
import { connect } from "react-redux";
import ListComponent from "../../../components/modules-components/match-relevance/ListItem/ListItem";
import Item from "../../../components/modules-components/match-relevance/ListItem/Item";
import {
  saveModal,
  setSelectedOpportunity,
} from "../../../dataflow/modules/modal-module";
import { formatDateWithoutTimezone } from "../../../util/format-value";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";

interface ListProps {
  setSelectedOpportunity: (v: any) => void;
  saveModal: ({ type, isOpen }: { type: string, isOpen: boolean }) => void;
  list: any;
  marginTop?: any;
  isHovering: any
  handleHover: (v: any) => void;
  handlenoHover: () => void;
}

const List: FC<ListProps> = ({
  setSelectedOpportunity,
  saveModal,
  list,
  marginTop,
  isHovering,
  handleHover,
  handlenoHover
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const normalizeScore = (score: any) => {
    if (score <= 1) {
      return 1 + "%";
    } else if (score < 100) {
      return Math.round(100 - 100 / score) + "%";
    } else {
      return 100 + "%";
    }
  };

  const handleOpportunityModal = (item: any) => {
    setSelectedOpportunity(item);
    saveModal({
      type: "open-opportunity",
      isOpen: true,
    });
  };

  const handleScreenSize = useCallback(() => {
    if (window.innerWidth <= 590) {
      setIsMobile(true);
    } else if (window.innerWidth > 590) {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    handleScreenSize();
  }, [handleScreenSize]);

  const renderListHeader = () => (
    <ListComponent>
      <Item bold>Fit</Item>
      <Item bold>Categoria</Item>
      <Item bold>Id</Item>
      {!isMobile && <Item bold>Título e Descrição</Item>}
      {!isMobile && <Item bold>Data Início</Item>}
      {!isMobile && <Item bold>Data Final</Item>}
    </ListComponent>
  );

  return (
    <Content marginTop={marginTop}>
      {marginTop && <Title>Oportunidades selecionadas</Title>}
      {!marginTop && renderListHeader()}

      {list.map((item: any, index: any) => {
        const dateStart = new Date(item.START_DATE);

        const dateEnd = new Date(item.END_DATE);

        let myColor = "#40404095";

        if (item.relevance < 50) {
          myColor = Colors.red;
        } else if (item.relevance >= 50 && item.relevance <= 70) {
          myColor = Colors.orange2;
        } else if (item.relevance > 70 && item.relevance <= 80) {
          myColor = Colors.green8;
        } else if (item.relevance > 80 && item.relevance <= 100) {
          myColor = Colors.green4;
        } else myColor = `${Colors.gray8}95`;

        return (
          <ListComponent
            key={`${index}-${item.OPPORT_NUM}`}
            handleHover={() => handleHover(item)}
            handlenoHover={handlenoHover}
            handleClick={() => {
              logEvent(
                eventCategory.modalOpen,
                "click modal de detalhes",
                `${item.OPPORT_DESCR}`
              );
              handleOpportunityModal(item);
            }}
          >
            <Item bold={isHovering === item} background={myColor}>
              {normalizeScore(item.score)}
            </Item>
            <Item bold={isHovering === item}>{item.COMPANY_DESC}</Item>
            <Item bold={isHovering === item}>{item.OPPORT_NUM}</Item>
            {!isMobile && <Item bold={isHovering === item}>{item.DESC_OBJ_CONTRAT}</Item>}
            {!isMobile && <Item bold={isHovering === item}>
              {formatDateWithoutTimezone(dateStart, "dd/MM/yyyy")}
            </Item>}
            {!isMobile && <Item bold={isHovering === item}>
              {formatDateWithoutTimezone(dateEnd, "dd/MM/yyyy")}
            </Item>}
          </ListComponent>
        );
      })}
    </Content>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  saveModal: (info: any) => {
    dispatch(saveModal(info));
  },
  setSelectedOpportunity: (info: any) => {
    dispatch(setSelectedOpportunity(info));
  },
});

export default connect(null, mapDispatchToProps)(List);
